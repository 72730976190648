import { ChangeEvent, FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import style from './RadioStyle.module.scss';
import RadioGroup from 'ui/common/radiogroup/RadioGroup';

const cx = classnames.bind(style);

interface IProps {
  size?: 'sm' | 'md' | 'lg';
  list: { render: ReactNode; value: string }[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value?: string;
}

const RadioStyle = ({ size = 'md', list, name, onChange, value: defaultValue }: IProps) => {
  return (
    // @ts-ignore
    <div className={cx('container')}>
      <RadioGroup
        id={name}
        name={name}
        onChange={(e) => {
          onChange(e);
        }}
        value={defaultValue}
      >
        {list.map(({ render, value }, index) => (
          <RadioGroup.Option key={render + value + index} value={value}>
            <RadioGroup.Consumer>
              {() => (
                <div
                  className={cx(
                    'border',
                    `border-${list.length}`,
                    `size-${size}`,
                    { active: value === defaultValue },
                    { margin: index === list.length - 1 }
                  )}
                >
                  {render}
                </div>
              )}
            </RadioGroup.Consumer>
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
};

export default RadioStyle;
